<template>
  <div id="landing">
    <header class="wide">
      <div class="name">
        <router-link to="/portfolio/all">
          <h3>Wellthmark</h3>
        </router-link>
      </div>
      <div class="logo">
        <router-link to="/portfolio/all">
          <img src="@/assets/logo.svg" alt="logo" />
          <!-- <img src="@/assets/logo.png" alt="logo" /> -->
        </router-link>
      </div>
      <div v-if="showMyAccount"></div>
      <div v-else class="login">
        <router-link to="/login">
          <button>
            <h5>Log In</h5>
          </button>
        </router-link>
      </div>
      <div v-if="showMyAccount" class="signup">
        <router-link to="/portfolio/all">
          <button>
            <h5>My Account</h5>
          </button>
        </router-link>
      </div>
      <div v-else class="signup">
        <router-link to="/signup">
          <button>
            <h5>Sign Up</h5>
          </button>
        </router-link>
      </div>
    </header>
    <section class="wide">
      <div class="row1">
        <div class="col1">
          <h1>Wellthmark</h1>
          <div class="slogan">
            <h3>Your health is your <span class="sloganHighlight">wellth</span>. Bench<span class="sloganHighlight">mark</span> it here. With<span class="sloganHighlight">
                <vue-typer
                :text='[" yourself."," your friends."," your teams."," your communities."," your demographic."," your goals."," athletes."," health care providers."," experts."," everyone."]'
                :repeat='Infinity'
                :shuffle='false'
                initial-action='typing'
                :pre-type-delay='70'
                :type-delay='70'
                :pre-erase-delay='2000'
                :erase-delay='250'
                erase-style='clear'
                :erase-on-complete='false'
                caret-animation='blink'
                ></vue-typer>
              </span>
            </h3>
          </div>
        </div>
      </div>
      <div class="row4">
        <div class="col1">
          <h2>Wellth: Your health in one number</h2>
          <h3>
            Our Wellthmark algorithm calculates your Wellth - 
            a number between 0 and 100 representing how healthy and fit you are - 
            based on your individual health and wellness markers.
          </h3>
        </div>
        <div class="col2">
          <img src="@/assets/howto4.png" alt="howto4" />
        </div>
      </div>
      <div class="row5">
        <div class="col2">
          <img src="@/assets/howto6.png" alt="howto4" />
        </div>
        <div class="col1">
          <h2>Your one stop shop for tracking your health</h2>
          <h3>
            When you improve your markers, your Wellth goes up. 
            Each marker has an optimal result based on professionally accepted standards 
            in medicine, fitness, and athletics.
          </h3>
          <!-- <h3>
            You may not have results for 
            all markers and that’s okay. The Wellthmark algorithm starts 
            calculating your Wellth with just one marker and becomes more 
            meaningful as you add more, providing valuable insights over time.
          </h3> -->
          <!-- screenshot of markers page and new results section -->
        </div>
      </div>
      <div class="row2">
        <div class="col1">
          <h2>Detailed and holistic</h2>
          <h3>
            Know your Wellth overall, for each individual marker, and by health category, 
            including cardiovascular endurance, body composition, flexibility, 
            heart health, and more.
          </h3>
        </div>
        <div class="col2">
          <img src="@/assets/howto5.png" alt="howto5" />
        </div>
      </div>
      <div class="row3">
        <div class="col2">
          <img src="@/assets/howto1.png" alt="howto1" />
        </div>
        <div class="col1">
          <h2>Easy and intuitive</h2>
          <h3>
            Get started today by adding markers and getting as close to 100 as possible. 
            Then, watch your Wellth grow!
          </h3>
          <!-- screenshot of markers page and new results section -->
        </div>
      </div>
      <!-- <div class="row4">
        <div class="col2">
          <img src="@/assets/howto2.png" alt="howto2" />
        </div>
        <div class="col1">
          <h3>Step 2: Track progress</h3>
          <p>Track your performance over time by marker, category, and overall.</p>
        </div>
      </div>
      <div class="row5">
        <div class="col1">
          <h3>Step 3: Grow your Wellth</h3>
          <p>Add to your overall Wellth by getting closer to 100 for each marker.</p>
        </div>
        <div class="col2">
          <img src="@/assets/howto3.png" alt="howto3" />
        </div>
      </div> -->
      <div class="row6">
        <div class="col1">
          <router-link to="/signup"><h3>Signup now to get started</h3></router-link>
        </div>
      </div>
      <hr>
      <footer>
        <p>
          &#169; 2021 Wellthmark. All rights reserved.
        </p>
        <p>
          Contact us at help@wellthmark.com
        </p>
        <p>
          <a href="https://forms.gle/UELeh1MX4RuWYZKQ9" target="_blank">Leave Feedback</a>
        </p>
        <p>
          <a href="https://forms.gle/4sxiLjPDLEaWK8LWA" target="_blank">Subscribe</a>
        </p>
      </footer>
    </section>
    <section class="narrow">
      <div class="row1">
        <div class="col1">
          <!-- <div class="current-wellth">
            <router-link to="/portfolio/all">
              <img src="@/assets/logo.svg" alt="logo" />
            </router-link>
            <h2>Wellthmark</h2>
          </div> -->
          <h2>Wellthmark</h2>
          <router-link to="/portfolio/all">
            <img src="@/assets/logo.svg" alt="logo" class="logo-img"/>
          </router-link>
          <div class="slogan">
            <p>
              Your health is your 
              <span class="sloganHighlight">wellth</span>.
            </p>
            <p>
              Bench<span class="sloganHighlight">mark</span> it here.
            </p>
          </div>
          <div v-if="showMyAccount"></div>
          <div v-else class="login">
            <router-link to="/login">
              <button>
                <h5>Log In</h5>
              </button>
            </router-link>
          </div>
          <div v-if="showMyAccount" class="signup">
            <router-link to="/portfolio/all">
              <button>
                <h5>My Account</h5>
              </button>
            </router-link>
          </div>
          <div v-else class="signup">
            <router-link to="/signup">
              <button>
                <h5>Sign Up</h5>
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row4">
        <div class="col1">
          <h3>Wellth: Your health in one number</h3>
          <h6>
            Our Wellthmark algorithm calculates your Wellth - 
            a number between 0 and 100 representing how healthy and fit you are - 
            based on your individual health and wellness markers.
          </h6>
          <img src="@/assets/howto4.png" alt="howto4" class="howto"/>
        </div>
      </div>
      <div class="row5">
        <div class="col1">
          <h3>Your one stop shop for tracking your health</h3>
          <h6>
            When you improve your markers, your Wellth goes up. 
            Each marker has an optimal result based on professionally accepted standards 
            in medicine, fitness, and athletics.
          </h6>
          <img src="@/assets/howto6.png" alt="howto4" class="howto"/>
          <!-- <h3>
            You may not have results for 
            all markers and that’s okay. The Wellthmark algorithm starts 
            calculating your Wellth with just one marker and becomes more 
            meaningful as you add more, providing valuable insights over time.
          </h3> -->
          <!-- screenshot of markers page and new results section -->
        </div>
      </div>
      <div class="row2">
        <div class="col1">
          <h3>Detailed and holistic</h3>
          <h6>
            Know your Wellth overall, for each individual marker, and by health category, 
            including cardiovascular endurance, body composition, flexibility, 
            heart health, and more.
          </h6>
          <img src="@/assets/howto5.png" alt="howto5" class="howto"/>
        </div>
      </div>
      <div class="row3">
        <div class="col1">
          <h3>Easy and intuitive</h3>
          <h6>
            Get started today by adding markers and getting as close to 100 as possible. 
            Then, watch your Wellth grow!
          </h6>
          <img src="@/assets/howto1.png" alt="howto1" class="howto"/>
          <!-- screenshot of markers page and new results section -->
        </div>
      </div>
      <div class="row6">
        <div class="col1">
          <router-link to="/signup"><h3>Signup now to get started</h3></router-link>
        </div>
      </div>
      <hr>
      <footer>
        <p>
          &#169; 2021 Wellthmark. All rights reserved.
        </p>
        <p>
          Contact us at help@wellthmark.com
        </p>
        <p>
          <a href="https://forms.gle/UELeh1MX4RuWYZKQ9" target="_blank">Leave Feedback</a>
        </p>
        <p>
          <a href="https://forms.gle/4sxiLjPDLEaWK8LWA" target="_blank">Subscribe</a>
        </p>
      </footer>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['userProfile']),
    showMyAccount() {
      if (this.userProfile.id) {
        return this.userProfile.id.length > 0
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

// colors
$primary: #3D4D62;
$secondary: #7399C6;
$error: #ef5777;
$darkGreen: #1FA482;
$lightGreen: #5CD0B5;
$lightest: #e3f2fa;
$light: #c3d6e2;
$medium: #7399C6;
$dark: #3D4D62;
$white: #FFFFFF;
$deteriorating: #ef5777;
$improving: #1FA482;
$greyedOut: #787f85;

// fonts
$primaryFont: 'Playfair Display';
$myFontSize: 18px;
$myWidth: 680px;

// landing
#landing {

  .wide {
    @media screen and (max-width: 742px) {
      display: none;
    }
  }

  .narrow {
    @media screen and (min-width: 742px) {
      display: none;
    }

    img {
      // box-shadow: 0 0 5px 0 rgba($dark, 0.5);
      -webkit-filter: none;
      filter: none;
    }

    .row2 {
      height: 80vh;
    }

    .row3 {
      height: 80vh;
    }

    .row4 {
      height: 80vh;
    }

    .row5 {
      height: 80vh;
    }
  }

  section.narrow {
    .col1 {
      // padding: 1vh;

      * {
        margin: 0 auto;
        text-align: center;
      }

      img.howto {
        height: 40%;
        width: 100%;
        object-fit: contain;
      }

      .slogan {
        padding: 1vh 0;
      }

      .current-wellth {
        // height: 6vh;
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content: left;

        img {
          height: 100%;
          width: 100%;
        }
      }

      img {
        height: 3rem;
        width: 100%;
        padding: 1vh;
      }

      .login {
        flex-basis: content;
        margin: 0;
        border: 0;
        padding: 5vh 0 0 0;

        a {
          button {
            background: $white;
            border: 1px solid $primary;
            padding: 0.5rem 2rem;
            border-radius: 7px;
            box-shadow: 0 0 5px 0 rgba($dark, 0.5);
            cursor: pointer;
          
            &:hover {
              background: $lightest;
            }
          
            &:disabled {
              opacity: 0.5;
          
              &:hover {
                background: $primary;
              }
            }
          }
        }
      }

      .signup {
        flex-basis: content;
        margin: 0;
        border: 0;
        padding: 2vh;

        a {
          button {
            background: $primary;
            border: 0;
            padding: 0.5rem 2rem;
            border-radius: 7px;
            box-shadow: 0 0 5px 0 rgba($dark, 0.5);
            cursor: pointer;

            h5 {
              color: $white;
            }
          
            &:hover {
              background: lighten($primary, 10%);
            }
          
            &:disabled {
              opacity: 0.5;
          
              &:hover {
                background: $primary;
              }
            }
          }
        }
      }
    }
  }

  header {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3.6rem;
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    background-color: $white;
    z-index: 100;

    .name {
      flex-basis: content;
      display: block;
      margin: 0;
      border: 0;
      padding: 3vh 0 3vh 3vh;

      a {
        height: 100%;
        margin: 0;
        border: 0;
        padding: 0;

        h3 {
          height: 100%;
          margin: 0;
          border: 0;
          padding: 0;
        }
      }
    }

    .logo {
      flex-basis: content;
      height: 100%;
      margin: 0;
      margin-right: auto;
      border: 0;
      padding: 2.2vh 2.2vh 2.2vh 0.5vh;

      a {
        height: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          height: 100%;
          margin: 0;
          border: 0;
          padding: 0;
        }
      }
    }

    .login {
      flex-basis: content;
      margin: 0;
      border: 0;
      padding: 2vh;

      a {
        button {
          background: $white;
          border: 1px solid $primary;
          padding: 0.5rem 2rem;
          border-radius: 7px;
          box-shadow: 0 0 5px 0 rgba($dark, 0.5);
          cursor: pointer;
        
          &:hover {
            background: $lightest;
          }
        
          &:disabled {
            opacity: 0.5;
        
            &:hover {
              background: $primary;
            }
          }
        }
      }
    }

    .signup {
      flex-basis: content;
      margin: 0;
      border: 0;
      padding: 2vh;

      a {
        button {
          background: $primary;
          border: 0;
          padding: 0.5rem 2rem;
          border-radius: 7px;
          box-shadow: 0 0 5px 0 rgba($dark, 0.5);
          cursor: pointer;

          h5 {
            color: $white;
          }
        
          &:hover {
            background: lighten($primary, 10%);
          }
        
          &:disabled {
            opacity: 0.5;
        
            &:hover {
              background: $primary;
            }
          }
        }
      }
    }
  }

  section {
    top: 3.6rem;
    height: 90vh;
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;

    img {
      // box-shadow: 0 0 5px 0 rgba($dark, 0.5);
      -webkit-filter: drop-shadow(0 0 10px rgba($dark, 0.8));
      filter: drop-shadow(0 0 10px rgba($dark, 0.8)); 
    }

    img.logo-img {
      // box-shadow: 0 0 5px 0 rgba($dark, 0.5);
      -webkit-filter: none;
      filter: none; 
    }

    .row1 {
      height: 100vh;
      background-color: $white;

      .col1 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        border: 0;
        padding: 5vh;

        .slogan {
          h3 {
            color: $secondary;
            line-height: 1.5;
          }

          p {
            color: $secondary;
            line-height: 1.5;
          }
        }

        h3 {
          color: $secondary;
          line-height: 1.5;
        }

        p {
          color: $secondary;
          line-height: 1.5;
        }
      }
    }

    .row2 {
      height: 100vh;
      background-color: $secondary;
      display: flex;
      flex-direction: row;
      
      .col1 {
        flex: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        border: 0;
        padding: 2vh;

        * {
          color: $white;
          margin-right: auto;
          padding: 2vh;
        }
      }

      .col2 {
        flex: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        border: 0;
        padding: 5vh;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .row3 {
      height: 100vh;
      background-color: $light;
      display: flex;
      flex-direction: row;
      
      .col1 {
        flex: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        border: 0;
        padding: 2vh;

        * {
          color: $white;
          margin-right: auto;
          padding: 2vh;
        }
      }

      .col2 {
        flex: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        border: 0;
        padding: 5vh;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .row4 {
      height: 100vh;
      background-color: $darkGreen;
      display: flex;
      flex-direction: row;
      
      .col1 {
        flex: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        border: 0;
        padding: 2vh;

        * {
          color: $white;
          margin-right: auto;
          padding: 2vh;
        }
      }

      .col2 {
        flex: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        border: 0;
        padding: 5vh;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .row5 {
      height: 100vh;
      background-color: $lightGreen;
      display: flex;
      flex-direction: row;
      
      .col1 {
        flex: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        border: 0;
        padding: 2vh;

        * {
          color: $white;
          margin-right: auto;
          padding: 2vh;
        }
      }

      .col2 {
        flex: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        border: 0;
        padding: 5vh;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .row6 {
      height: 10vh;
      background-color: $white;
      
      .col1 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        border: 0;
        padding: 5vh;

        * {
          margin: auto;

          &:hover {
            color: $secondary
          }
        }
      }
    }
  }
}

hr {
  opacity: 0.3;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vh 1vh 5vh 1vh;

  * {
    margin: auto;
    padding: 1vh;
    color: $greyedOut;

    a {
      &:hover {
        // background-color: #000;
        color: $primary;
      }
    }
  }
}

</style>